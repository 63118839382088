import React, { useState } from "react";
import CartProduct from "../../components/cartProduct/CartProduct";
import RadioGroup from "../../components/radioGroup/RadioGroup";
import Total from "../../components/total/Total";
import { useSelector } from "react-redux";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useNavigate } from "react-router-dom";
import { OrderService } from "../../services/services";
import { useFirebase } from 'react-redux-firebase'
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


export default function CheckoutPage() {
    const [snackMessage, setSnackMessage] = useState("");
    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const user = useSelector((state) => state.firebase.auth);
    const products = useSelector((state) => state.cart.cartItems);
    const amount = useSelector((state) => state.cart.amount);
    const navigate = useNavigate();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [details, setDetails] = useState("");
    const [region, setRegion] = useState("");
    const [town, setTown] = useState("");
    
    const check = firstname && lastname && phoneNumber && address && region && town;
    
    const [paymentType, setPaymentType] = useState("");
    
    const config = {
        public_key: 'FLWPUBK_TEST-77dcc6af5ff27c6f31b39ed9dc0cbcc7-X',
        tx_ref: Date.now(),
        amount: amount,
        currency: 'UGX',
        payment_options: paymentType,
        customer: {
            email: user.email,
            phonenumber: user.phoneNumber,
            name: user.displayName,
        },
        customizations: {
            title: 'HudA Clothing',
            description: 'Payment for items in cart',
            logo: 'https://hudaclothing.net/logo192.png',
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    const addOrder = () => {
        if (!check) {
            handleClick();
            setSnackMessage("Fill in your address details");
            return;
        }

        const order = {
            uid: user.uid,
            status: "pending",
            date: new Date().getTime(),
            paymentType: paymentType,
            customer: {
                name: user.displayName,
                email: user.email,
            },
            address: {
                firstname: firstname,
                lastname: lastname,
                phoneNumber: phoneNumber,
                location: address,
                details: details,
                region: region,
                town: town,
            },
            cart: {
                amount: amount,
                products: products
            },
            payment: {}
        }

        OrderService.create(order).then((docRef) => {
            if (paymentType == 'cash') {
                navigate('/order-placed');
                return;
            }
            handleFlutterPayment({
                callback: (response) => {
                    console.log(response);
                    response.redirectstatus = "";
                    OrderService.update(docRef.id, { payment: response })
                        .then(() => {
                            navigate('/payment');
                        })
                        .finally(() => {
                            closePaymentModal() // this will close the modal programmatically
                        });
                },
                onClose: () => { },
            });
        });
    }

    return (
        <div className="py-6">

            <div className="max-w-md mx-auto rounded-lg  md:max-w-5xl">
                <div className="md:flex ">
                    <div className="w-full px-2 md:px-5 py-5">
                        <div className="max-w-md mx-auto md:max-w-5xl py-4">
                            <h1 className="text-xl font-medium mx-4">Checkout</h1>
                        </div>

                        <div className="md:grid md:grid-cols-3 gap-2 ">

                            <div className="col-span-2 px-0 md:px-5 w-full">
                                <div className="mx-auto w-full rounded-md bg-gray-100 shadow-lg p-2">
                                    <Disclosure defaultOpen>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                    <span>Address Details</span>
                                                    <ChevronUpIcon
                                                        className={`${open ? 'rotate-180 transform' : ''
                                                            } h-5 w-5 text-purple-500`}
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                                                    <div className="p-4">
                                                        <div className="grid md:grid-cols-2 md:gap-2">
                                                            <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="First name*"
                                                                value={firstname}
                                                                onChange={(e) => setFirstname(e.target.value)}
                                                            />
                                                            <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="Last name*"
                                                                value={lastname}
                                                                onChange={(e) => setLastname(e.target.value)}
                                                            />
                                                        </div>
                                                        <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="Phone Number*"
                                                            value={phoneNumber}
                                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                                        />
                                                        <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="Address*"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                        />
                                                        <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="Apartment, suite, etc. (optional)"
                                                            value={details}
                                                            onChange={(e) => setDetails(e.target.value)}
                                                        />
                                                        <div className="grid md:grid-cols-2 md:gap-2">
                                                            <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="Region*"
                                                                value={region}
                                                                onChange={(e) => setRegion(e.target.value)}
                                                            />
                                                            <input type="text" name="mail" className="border rounded h-10 w-full focus:outline-none focus:border-green-200 px-2 mt-2 text-sm" placeholder="District/Town/Area*"
                                                                value={town}
                                                                onChange={(e) => setTown(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="flex justify-between items-center pt-6">
                                                            <button type="button" className="h-10 w-full rounded-md font-medium text-md bg-primary text-white">Next Step</button>
                                                        </div>
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    <Disclosure as="div" className="mt-2">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                    <span>Delivery Method</span>
                                                    <ChevronUpIcon
                                                        className={`${open ? 'rotate-180 transform' : ''
                                                            } h-5 w-5 text-purple-500`}
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                            </div>




                            <div className="mt-4 md:mt-0 p-5 bg-gray-800 rounded-md overflow-visible">
                                <div id="summary" className="px-4 py-5 text-gray-200">
                                    <h1 className="font-semibold text-2xl border-b pb-4">Order Summary</h1>
                                    <div className="flex justify-between mt-8">
                                        <span className="font-semibold text-sm">Amount</span>
                                        <span className="font-semibold text-sm">UGX {amount.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <span className="font-semibold text-sm">Discount</span>
                                        <span className="font-semibold text-sm">0%</span>
                                    </div>
                                    <div className="pt-5 pb-6 border-b">
                                        <label htmlFor="promo" className="block text-gray-300 text-sm font-bold mb-2">Promo Code</label>
                                        <div className="flex justify-between" >
                                            <input type="text" id="promo" placeholder="Enter your code" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                            <button className="bg-red-500 hover:bg-red-600 ml-1 px-5 py-2 text-sm text-white rounded-md">Apply</button>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="block text-gray-300 text-sm font-bold mb-2 mt-4">Payment Method</h3>
                                        <RadioGroup setPaymentType={setPaymentType} />
                                    </div>
                                    <div className="border-t mt-8">
                                        <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                                            <span>Total cost</span>
                                            <span>UGX {amount.toLocaleString()}</span>
                                        </div>
                                        <button className="bg-primary font-semibold py-3 text-sm text-white uppercase w-full rounded-md"
                                            onClick={addOrder}
                                        >
                                            Confirm Order
                                        </button>
                                    </div>
                                </div>

                            </div>


                        </div>


                    </div>
                </div>
            </div>

            <div>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message={snackMessage}
                    action={action}
                />
            </div>
        </div>
    );
}