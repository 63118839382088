import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { StarIcon } from "@heroicons/react/solid";
import { RadioGroup } from "@headlessui/react";
import { db } from "../../huda-config";
import { CircularProgress, Rating, Typography } from "@mui/material";
import { addToCart } from "../../features/CartSlice";
import SEO from "../../components/seo/SEO";
import ReviewCard from "../../components/reviewCard/ReviewCard";
import firebase from "firebase/compat/app";

// material-ui components
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteOutlined from "@mui/icons-material/FavoriteBorderOutlined";
import Backdrop from "@mui/material/Backdrop";
import { ProductService } from "../../services/services";
import ProductSection from "../../components/productSection/ProductSection";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductOverview() {
  // check for the user
  const user = useSelector((state) => state.user);
  const wishListItems = useSelector((state) => state.user.wishList);

  // handle select color state
  const [product, setProduct] = useState({ id: "", data: {} });
  const [similarProducts, setSimilarProducts] = useState([]);
  const [colour, setColour] = useState("");
  const [sizze, setSizze] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [myRating, setMyRating] = useState(0);
  const [myReview, setMyReview] = useState("");
  const [canReview, setCanReview] = useState(false);

  // getting routing params
  const params = useParams();
  let { productId } = params;

  // fetch item from the database
  useEffect(() => {
    db.collection("products")
      .doc(productId)
      .onSnapshot((snapshot) => {
        setProduct({ id: snapshot.id, data: snapshot.data() });
        getSimilarProducts(snapshot.data().category);
      });
  }, [productId]);

  // dispatch to cart a new product
  let dispatch = useDispatch();
  const addItemToCart = () => {
    const check = colour || sizze;
    if (product.data && check) {
      dispatch(
        addToCart({
          id: product.id,
          data: {
            images: product.data.images[0],
            description: product.data.description,
            title: product.data.title,
            sizes: sizze,
            colors: colour,
            category: product.data.category,
            price: product.data.price,
          },
        })
      );
      handleClick();
      setSnackMessage("product added to cart");
    } else {
      // handleClick();
      if (product.data.sizes.length === 0) {
        setSnackMessage("select color");
        handleClick();
      } else if (product.data.sizes !== null) {
        setSnackMessage("select size and color");
        handleClick();
      }
    }
  };


  const handleUserReview = () => {
  }

  const writeReview = () => {
    if (user.userId === "") {
      handleClick();
      setSnackMessage("You need to be signed in");
      return;
    }

    setCanReview(true);
  }

  const sendReview = () => {
    const userReview = {
      rating: myRating,
      text: myReview,
      name: user.username,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    }

    ProductService.update(productId, {
      ['review.' + user.userId]: userReview,
      'rating.total': firebase.firestore.FieldValue.increment(myRating),
      'rating.numberOf': firebase.firestore.FieldValue.increment(1),
      ['rating.stars.' + myRating]: firebase.firestore.FieldValue.increment(1),
    })
      .then(() => {
        setCanReview(false);
        handleClick();
        setSnackMessage("Review sent");
      })
      .catch((error) => {
        handleClick();
        setSnackMessage(error.message);
      });
  }

  const getSimilarProducts = category => {
    db.collection("products").where('category', '==', category).limit(4).onSnapshot((snapshot) => {
      setSimilarProducts(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          product: doc.data(),
        }))
      );
    });
  }

  // add to wishlist and rating too
  const addToWishList = () => {
    if (user.userId !== "") {
      db.collection("users")
        .doc(user.userId)
        .update({
          userWishList: firebase.firestore.FieldValue.arrayUnion({
            productId,
            title: product.data.title,
            images: product.data.images[0],
            price: product.data.price,
            description: product.data.description,
            category: product.data.category,
          }),
        })
        .then(() => {
          handleClick();
          setSnackMessage("added to wish list");
        })
        .catch((error) => {
          handleClick();
          setSnackMessage(error.message);
        });
    } else {
      handleClick();
      setSnackMessage("sign In to continue");
    }
  };

  // check the wishlist if exists
  const toCheck = () => {
    if (user.userId !== "") {
      if (wishListItems.length !== 0) {
        const see = wishListItems.some((item) => item.productId === productId);
        return see;
      }
    }
  };

  /**
   * handle the snackbar
   */
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  );

  // navigate to image page
  const [openFullImage, setOpenFullImage] = useState(false);
  const [tempImage, setTempImage] = useState("");
  const viewFullImage = (tempImages) => {
    setOpenFullImage(true);
    setTempImage(tempImages);
  };

  if (product.id === "")
    return (
      <div className="w-screen flex flex-wrap flex-row place-items-center">
        <CircularProgress />
      </div>
    );
  else if (product.data === undefined) return <div>Product not found</div>;
  else
    return (
      <div className="pt-2">
        <SEO
          image={product.data.images[0]}
          title={product.data.title}
          siteTitle="HudA clothing"
          description={product.data.description}
        />
        {/* Product info */}
        <div className="max-w-2xl mx-auto pt-2 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 grid md:grid-cols-2 gap-x-2 md:gap-x-8">
          <div className="lg:border-r lg:border-gray-200 lg:pr-2">
            {/* Image gallery */}
            <div className="max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-4 grid grid-cols-2 gap-x-2 md:gap-x-4">
              <div
                className="max-h-[90vh] aspect-w-3 aspect-h-4 rounded-lg overflow-hidden lg:block"
                onClick={() => viewFullImage(product.data.images[0])}
              >
                <img
                  src={product.data.images[0]}
                  alt={product.data.title}
                  className="w-full h-full object-center object-cover cursor-pointer"
                />
              </div>
              <div className="max-h-[90vh] grid grid-cols-1 gap-y-2 md:gap-y-4">
                <div
                  className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden"
                  onClick={() =>
                    viewFullImage(
                      product.data.images[1] ?? product.data.images[0]
                    )
                  }
                >
                  <img
                    src={product.data.images[1] ?? product.data.images[0]}
                    alt={product.data.title}
                    className="w-[250px] rounded-lg object-center object-cover cursor-pointer"
                  />
                </div>
                <div
                  className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden border-2"
                  onClick={() => viewFullImage(product.data.images[0])}
                >
                  <img
                    src={product.data.images[0]}
                    alt={product.data.title}
                    className="w-[250px] object-center object-contain rounded-lg cursor-pointer"
                  />
                </div>
              </div>
              <Backdrop
                open={openFullImage}
                className="z-20"
                onClick={() => setOpenFullImage(false)}
              >
                <div className=" h-full w-full backdrop-blur-[2px] flex justify-center items-center">
                  <img src={tempImage} alt="" className="h-[90vh]" />
                </div>
              </Backdrop>
            </div>
          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
            <h2 className="sr-only">Product information</h2>
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl capitalize mb-2">
              {product.data.title}
            </h1>
            <p className="text-3xl text-gray-900">
              UGX {product.data.price.toLocaleString()}
            </p>

            {/* Colors */}
            <div className="mt-10">
              <h3 className="text-sm text-gray-900 font-medium">Color</h3>

              <RadioGroup value={colour} onChange={setColour} className="mt-4">
                <RadioGroup.Label className="sr-only">
                  Choose a color
                </RadioGroup.Label>
                <div className="flex items-center space-x-3">
                  {product.data.colors.map((color) => (
                    <RadioGroup.Option
                      key={color}
                      value={color}
                      style={{ backgroundColor: color }}
                      className={({ active, checked }) =>
                        classNames(
                          `bg-${color}-400`,
                          active && checked ? "ring ring-offset-1" : "",
                          !active && checked ? "ring-2" : "",
                          "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
                        )
                      }
                    >
                      <RadioGroup.Label as="span" className="sr-only">
                        {color}
                      </RadioGroup.Label>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          `bg-${color}-400`,
                          "h-8 w-8 border border-black border-opacity-10 rounded-full"
                        )}
                      />
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            {/* Sizes */}
            <div className="mt-10">
              {product.data.sizes.length !== 0 ? (
                <div className="flex items-center justify-between">
                  <h3 className="text-sm text-gray-900 font-medium">Size</h3>

                </div>
              ) : null}

              <RadioGroup value={sizze} onChange={setSizze} className="mt-4">
                <RadioGroup.Label className="sr-only">
                  Choose a size
                </RadioGroup.Label>
                <div className="grid grid-cols-6 gap-4 sm:grid-cols-8 lg:grid-cols-6">
                  {product.data.sizes.map((size) => (
                    <RadioGroup.Option
                      key={size}
                      value={size}
                      className={({ active }) =>
                        classNames(
                          true
                            ? "bg-white shadow-sm text-gray-900 cursor-pointer"
                            : "bg-gray-50 text-gray-200 cursor-not-allowed",
                          active ? "ring-2 ring-indigo-500" : "",
                          "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <RadioGroup.Label as="span">{size}</RadioGroup.Label>
                          {true ? (
                            <span
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-indigo-500"
                                  : "border-transparent",
                                "absolute -inset-px rounded-md pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          ) : (
                            <span
                              aria-hidden="true"
                              className="absolute -inset-px rounded-md border-2 border-gray-200 pointer-events-none"
                            >
                              <svg
                                className="absolute inset-0 w-full h-full text-gray-200 stroke-2"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                                stroke="currentColor"
                              >
                                <line
                                  x1={0}
                                  y1={100}
                                  x2={100}
                                  y2={0}
                                  vectorEffect="non-scaling-stroke"
                                />
                              </svg>
                            </span>
                          )}
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            <div className="flex mt-10 ">
              <button
                onClick={addItemToCart}
                className="w-full bg-primary rounded-md py-3 px-8 text-base font-medium text-white focus:outline-none
                  focus:ring-2 focus:ring-offset-2"
              >
                Add to bag
              </button>

              {/* add to favorites */}
              <button
                onClick={addToWishList}
                className="bg-white hover:bg-red-200 p-3 ml-2 rounded-full"
              >
                {toCheck() ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteOutlined color="action" />
                )}
              </button>
            </div>

            {/* Description and details */}
            <div className="mt-10">
              <h3 className="text-sm text-gray-900 font-medium">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">
                  {product.data.description}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-3 py-8 px-4 md:px-12 lg:px-24 w-full">
          <div className="md:col-span-2 mx-auto max-w-4xl w-full flex flex-col pr-8">
            <div className="pb-5 w-full flex justify-between items-baseline border-b-2 border-gray-200">
              <h3 className="text-xl sm:text-3xl text-gray-700 font-bold">Customer Reviews</h3>
            </div>

            {/* :REVIEWS */}
            <div className="mt-4">
              {product.data.review == null
              ? <div className="flex flex-col w-full h-[100%] p-5 bg-gray-300 rounded-md">
                Be the first to review this product
              </div>
              : Object.values(product.data.review).map((review, index) => {
                let normalDate = new Date(review.date.seconds).toLocaleString('en-GB', { timeZone: 'UTC' })
                return <article key={index} className={`py-5 flex flex-col sm:flex-row ${index !== 0 && "border-t-2 border-gray-100"}`}>
                  <div className="flex flex-row sm:flex-col items-center sm:items-center">
                    <span className="flex-shrink-0 inline-block border-2 border-gray-50 rounded-full overflow-hidden" aria-label="avatar">
                      <img src='/usericon.png' alt="" className="w-12 h-12" />
                    </span>
                    <div className="ml-3 sm:ml-0 space-y-1.5 sm:hidden w-full">
                      <div className="flex justify-between">
                        <p className="text-sm text-gray-700 font-semibold whitespace-nowrap ">{review.name}</p>
                        <ReviewCard rating={review.rating} color="text-gray-700" />
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-700 font-semibold whitespace-nowrap ">{normalDate}</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[100%] mt-5 sm:mt-0 sm:ml-3 px-4 space-y-2">
                    <div className="hidden sm:flex justify-between">
                      <p className="text-sm text-gray-700 font-semibold whitespace-nowrap ">{review.name}</p>
                      <ReviewCard rating={review.rating} color="text-gray-700" />
                      <p className="text-sm text-gray-700 font-semibold whitespace-nowrap ">{normalDate}</p>
                    </div>
                    <p className="text-sm text-gray-500 font-medium">{review.text}</p>
                  </div>
                </article>
              })}
            </div>
          </div>

          <div className="border-l-2">
            {product.data.rating == null
            ? <></>
            : <div className="mb-1 tracking-wide px-4 py-4" >
              <h2 className="flex w-full text-gray-800 font-semibold mt-1 items-center justify_between">
                <div className="mr-4"><Rating value={product.data.rating.total / product.data.rating.numberOf} /></div>
                {(product.data.rating.total / product.data.rating.numberOf).toFixed(1)}
              </h2>
              <div className="border-b -mx-8 px-8 pb-3">
                {[5,4,3,2,1].map((e, i) => {
                  const rate = product.data.rating.stars[e] ?? 0;
                  const percent = ((rate / product.data.rating.numberOf)  * 100).toFixed(0);
                  const perc = rate === 0 ? 0 : `${rate}/${product.data.rating.numberOf}`;
                  return <div className="flex items-center mt-1" key={i}>
                    <div className=" w-1/5 text-indigo-500 tracking-tighter">
                      <span>{e} star</span>
                    </div>
                    <div className="w-3/5">
                      <div className="bg-gray-300 w-full rounded-lg h-2">
                        <div className={classNames(`w-${perc} bg-indigo-600 rounded-lg h-2`)}></div>
                      </div>
                    </div>
                    <div className="w-1/5 text-gray-700 pl-3">
                      <span className="text-sm">{percent}%</span>
                    </div>
                  </div>})}
              </div>
            </div>}
            <div className="w-full px-4 mt-8">
              <h3 className="font-medium tracking-tight">Review this item</h3>
              <p className="text-gray-700 text-sm py-1">
                give your opinion about this item.
              </p>

              {!canReview
              ? <button className="bg-gray-100 border border-gray-400 px-3 py-1 rounded  text-gray-800 mt-2" onClick={writeReview}>
                write a review
              </button>
              : <>
              <label className="block uppercase text-gray-700 text-xs font-bold mt-4 mb-2" htmlFor="message">Rating</label>
              <Rating
                name="simple-controlled"
                value={myRating}
                onChange={(event, newValue) => { setMyRating(newValue) }}
              />
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2 mt-4" htmlFor="message">Review</label>
              <textarea maxLength="300" name="feedback" id="feedback" rows="4"
                cols="80"
                className="border-0 px-3 py-3 bg-gray-300 placeholder-black text-gray-800 rounded text-sm shadow focus:outline-none w-full"
                placeholder="" required
                value={myReview}
                onChange={e => setMyReview(e.target.value)}
                >
              </textarea>
              <button className="w-half justify-end bg-primary rounded-md py-3 px-8 text-base font-medium text-white"
                    onClick={sendReview}
              >
                Send
              </button>
              </>}
            </div>
          </div>
        </div>

        <div>
          <ProductSection title="Similar Products" data={similarProducts} />
        </div>

        <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8"></div>

        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          message={snackMessage}
          action={action}
        />
      </div>
    );
}

const reviews = [
  {
    id: 1,
    author: "Alenor Pompy",
    title: "The best on the market",
    review: "In rem ex sint numquam dolorum nobis officiis voluptatibus fugiat possimus non! Quasi molestias tempora deserunt laudantium voluptatum neque impedit, quae earum?",
    rating: 4,
    picture: "https://fancytailwind.com/static/profile3-7d5e2246807e801f5e5037c1234fc121.jpg",
  },
  {
    id: 2,
    author: "Kalvin Tran",
    title: "A little bit disappointed",
    review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum inventore, officia alias laudantium vitae sequi aut architecto, mollitia harum beatae voluptas ducimus minima porro et repellat, possimus odio odit. Suscipit.",
    rating: 3,
    picture: "https://fancytailwind.com/static/profile11-1fb6186c90e177fe59c5c85f946627d0.jpg",
  },
  {
    id: 3,
    author: "Felipe Weaver",
    title: "Cannot live without it",
    review: "Quos, illo accusamus suscipit repellat non ullam. Placeat quo magni laborum, ex repellendus voluptatibus laudantium libero facere mollitia vero ipsa voluptate omnis. Nulla sequi vel nostrum tempora facere facilis minima dignissimos dolore fuga ad, quia blanditiis deleniti inventore animi, porro corporis ex dicta nam!",
    rating: 5,
    picture: "https://fancytailwind.com/static/profile9-20177de8c21310d98e1c1c35f8fff271.jpg",
  },
]