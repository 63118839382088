import React from "react";

// importing material-ui components
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function MiniHeader() {
  return (
    <div
      className="hidden md:flex justify-center py-2"
      style={{ backgroundColor: "#1b03a3" }}
    >
      <div className="flex w-[95%] justify-between">
        <div className="flex w-1/6 justify-between  text-white">
          <div className="flex">
            <p className="font-bold uppercase">eng</p>
            <KeyboardArrowDownIcon />
          </div>
          <a href='https://m.facebook.com/1868113136537597/'><FacebookIcon /></a>
          <a href='https://instagram.com/hudaclothingbrand?igshid=YmMyMTA2M2Y='><InstagramIcon /></a>
          <a href='#'><TwitterIcon /></a>
        </div>
        <div className="flex items-center  text-white font-bold">
          <p className="mx-4">FAQs</p>
          <p>Call Us: <a href="tel:256783611137" className="hover:underline cursor-pointer"> +256 783 611137</a></p>
        </div>
      </div>
    </div>
  );
}

export default MiniHeader;
