import React, { useEffect, useState } from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

// importing material-ui
import CircularProgress from "@mui/material/CircularProgress";

function Menu({ data }) {
  // function to get categories without duplicates

  const [tempLinks, setTempLinks] = useState([]);
  const newLinks = [];
  useEffect(() => {
    const tempp = [];
    data.map((dt) => {
      const { product } = dt;
      tempp.push(product.category);
      if (tempp.length === data.length) {
        setTempLinks(tempp);
      }
    });
  }, [data]);

  // console.log(tempLinks);
  const uniqueLink = (arr) => {
    for (var i = 0; i < arr.length; i++) {
      if (newLinks.indexOf(arr[i]) === -1) {
        newLinks.push(arr[i]);
      }
    }
    return newLinks;
  };

  var unique = uniqueLink(tempLinks);

  return (
    <div className="menu absolute left-0 top-[40px] w-full text-white">
      {unique.length === 0 ? (
        <div className="flex justify-center items-center my-7">
          <CircularProgress />
        </div>
      ) : (
        unique.map((unq, index) => (
          <Link to={`${unq}`} key={index}>
            <p>{unq}</p>
          </Link>
        ))
      )}
    </div>
  );
}

export default Menu;
