import React, { useState } from "react";
import SignUp from "./SignUp";
import SignIn from "./SignIn";

// material-ui components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function Auth() {
  const [authOption, setAuthOption] = useState(true);

  // snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
      <div className="flex justify-center">
        {authOption ? (
          <SignIn
            authOption={authOption}
            setAuthOption={setAuthOption}
            snackBar={handleClick}
            setSnackMessage={setSnackMessage}
          />
        ) : (
          <SignUp
            authOption={authOption}
            setAuthOption={setAuthOption}
            snackBar={handleClick}
            setSnackMessage={setSnackMessage}
          />
        )}
        <div>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={snackMessage}
            action={action}
          />
        </div>
      </div>
  );
}

export default Auth;
