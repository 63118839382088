import React, { useState } from "react";
import "./auth.css";
import { auth, db } from "../../huda-config";

// import material-ui components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Providers from "./Providers";

function SignUp(props) {
  let { authOption, setAuthOption, snackBar, setSnackMessage } = props;

  // managing auth option states
  const [names, setNames] = useState({
    firstName: "",
    lastName: "",
  });
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState({
    password: "",
    showPassword: false,
  });
  const handlePassword = () => {
    setPass({
      ...pass,
      showPassword: !pass.showPassword,
    });
  };

  const check = names.firstName && names.lastName && pass.password && email;
  const createAccount = () => {
    if (check) {
      auth
        .createUserWithEmailAndPassword(email, pass.password)
        .then((user) => {
          user.user.updateProfile({
            displayName: `${names.firstName} ${names.lastName}`,
          });
          db.collection("users")
            .doc(user.user.uid)
            .set({
              username: `${names.firstName} ${names.lastName}`,
              userId: user.user.uid,
              userPhotoUrl: user.user.photoURL,
              userWishList: [],
            });
          setNames({
            firstName: "",
            lastName: "",
          });
          setPass({
            password: "",
            showPassword: "false",
          });
          setEmail("");
          snackBar();
          setSnackMessage("account created");
          window.location.href = '/';
        })
        .catch((error) => {
          snackBar();
          setSnackMessage(error.message);
        });
    } else {
      snackBar();
      setSnackMessage("fill in all the fields");
    }
  };

  return (
    <div className="antialiased w-full md:w-[80%] bg-grey-lightest rounded-md">
      <div className="w-full bg-grey-lightest pt-4">
        <div className="container mx-auto py-8">
          <div className="w-5/6 lg:w-1/2 mx-auto bg-white rounded shadow">
            <div className="py-4 px-8 text-black text-xl border-b border-grey-lighter">Create a free account</div>
            <div className="py-4 px-8">
              <div className="flex flex-col md:flex-row mb-4">
                <div className="w-full md:w-1/2">
                  <label className="block text-grey-darker text-sm font-medium mb-2" htmlFor="first_name">First Name</label>
                  <input className="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="first_name" type="text" placeholder="first name"
                    value={names.firstName}
                    onChange={(e) => setNames({ ...names, firstName: e.target.value })}
                  />
                </div>
                <div className="mx-2 my-2" />
                <div className="w-full md:w-1/2">
                  <label className="block text-grey-darker text-sm font-medium mb-2" htmlFor="last_name">Last Name</label>
                  <input className="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="last_name" type="text" placeholder="last name"
                    value={names.lastName}
                    onChange={(e) => setNames({ ...names, lastName: e.target.value })}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-medium mb-2" htmlFor="email">Email Address</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="email" placeholder="email address" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          />
              </div>
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-medium mb-2" htmlFor="password">Password</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="password" type="password" placeholder="secure password"
                  value={pass.password}
                  onChange={(e) => setPass({ ...pass, password: e.target.value })} 
                  />
                <p className="text-grey text-xs mt-1">At least 6 characters</p>
              </div>
              <div className="flex items-center justify-between mt-8">
                <button className="bg-primary text-white font-medium py-2 px-4 rounded-md w-full md:w-[50%]" onClick={createAccount}>
                  Sign Up
                </button>
              </div>
            </div>
          </div>
          <p className="text-center my-4">
            <a className="text-grey-dark text-sm no-underline hover:text-grey-darker cursor-pointer" onClick={() => setAuthOption(!authOption)}>
              I already have an account
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
