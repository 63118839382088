import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XCircleIcon } from "@heroicons/react/outline";
import {
  reduceNumber,
  removeFromCart,
  addToCart,
  calculateAmount,
} from "../../features/CartSlice";

// import material-ui components
import Button from "@mui/material/Button";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";

function CartProduct({ product }) {
  const { id, data, cartQuantity } = product;
  console.log(product);

  // dispatch to remove product
  const dispatch = useDispatch();
  const removeItem = () => {
    dispatch(removeFromCart(id));
  };

  // item number increament and decreament
  const increament = () => {
    dispatch(addToCart(product));
  };

  const decreament = () => {
    dispatch(reduceNumber(product));
  };

  // compute total
  let total = cartQuantity * data.price;

  //
  const cart = useSelector((state) => state.cart);
  useEffect(() => {
    dispatch(calculateAmount());
  }, [cart, dispatch]);

  return (
    <Link to={`/${data.category}/${id}/${data.title} `}>
      <div className="flex justify-between items-center my-2 py-2 px-2 cursor-pointer rounded-md hover:bg-gray-200">
        <div className="flex items-center">
          <div className="w-16 aspect-square rounded-lg overflow-hidden">
            <img
              src={data.images}
              alt={data.title}
              className="w-full h-full object-center object-cover"
            />
          </div>
          <div className="flex flex-col ml-3">
            <span className="md:text-md font-medium capitalize">{data.title}</span>
            <span className="text-xs font-light text-gray-400 mt-2">UGX {(data.price ?? 0).toLocaleString()}</span>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <div className="pr-8 flex ">
            <span className="font-semibold cursor-pointer" onClick={decreament}>-</span>
            <input type="text" className="focus:outline-none bg-gray-100 border h-6 w-8 rounded text-sm px-2 mx-2" value={cartQuantity} readOnly />
            <span className="font-semibold cursor-pointer" onClick={increament}>+</span>
          </div>

          <div className="pr-8">
            <span className="text-xs font-medium whitespac-nowrap">UGX {(total ?? 0).toLocaleString()}</span>
          </div>
          <XCircleIcon className="w-6 h-6 text-red-400 cursor-pointer" onClick={removeItem} />
        </div>
      </div>
    </Link>
  )
}

export default CartProduct;
