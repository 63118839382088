import React, { useState } from "react";
import "./auth.css";
import { auth } from "../../huda-config";

// material-ui components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Providers from "./Providers";

function SignIn(props) {
  let { authOption, setAuthOption, snackBar, setSnackMessage } = props;

  // auth states
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    showPassword: false,
    password: "",
  });
  const showPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  // function to signIn user
  const check = email && values.password;
  const signInUser = () => {
    if (check) {
      auth
        .signInWithEmailAndPassword(email, values.password)
        .then((user) => {
          snackBar();
          setSnackMessage(`welcome back ${user.user.displayName}`);
          window.location.href = '/';
        })
        .catch((error) => {
          snackBar();
          setSnackMessage(error.message);
        });
    } else {
      snackBar();
      setSnackMessage("fill in all fields");
    }
  };

  return (
    <div className="antialiased w-full md:w-[70%] bg-grey-lightest rounded-md">
      <div className="w-full bg-grey-lightest pt-4">
        <div className="container mx-auto py-8">
          <div className="w-5/6 lg:w-1/2 mx-auto bg-white rounded shadow">
            <div className="py-4 px-8 text-black text-xl border-b border-grey-lighter">Log Into Account</div>
            <div className="py-4 px-8">
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-medium mb-2" htmlFor="email">Email Address</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="email" placeholder="email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-medium mb-2" htmlFor="password">Password</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="password" type="password" placeholder="secure password"
                  value={values.password}
                  onChange={(e) => setValues({ ...values, password: e.target.value })}
                />
                <p className="text-grey text-xs mt-1">At least 6 characters</p>
              </div>
              <div className="flex items-center justify-between mt-8">
                <button className="bg-primary text-white font-medium py-2 px-4 rounded-md w-full md:w-[50%]" onClick={signInUser}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
          <p className="text-center my-4">
            <a className="text-grey-dark text-sm no-underline hover:text-grey-darker cursor-pointer" onClick={() => setAuthOption(!authOption)}>
              Dont have an account? Create Account
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
