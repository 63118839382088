import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { cartItems } from "../../features/CartSlice";
import { auth } from "../../huda-config";
import logo from "../../assets/huda-logo.png";
import { useNavigate } from "react-router-dom";

/*
 * material-ui components
 *
 */
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Badge from "@mui/material/Badge";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { IconButton } from "@mui/material";

function Header() {
  const items = useSelector(cartItems);
  const wishList = useSelector((state) => state.user.wishList);

  const [open, setOpen] = useState(false);
  const openNav = () => {
    setOpen(!open);
  };

  // function to open the search
  const navigate = useNavigate();
  const openSearch = () => {
    navigate("/search");
  };
  return (
    <div className="flex justify-center">
      <div className="w-[95%] grid grid-cols-4 py-2">
        <div className="w-[100%]">
          <Link to="/">
            <img
              src={logo}
              alt=""
              style={{ height: "50px", objectFit: "contain" }}
            />
          </Link>
        </div>
        <div className="hidden md:flex  col-span-2 border-2 border-gray-300 px-2 items-center justify-between rounded-md">
          <div
            className="outline-none bg-transparent w-[90%] mx-2 rounded-md"
            onClick={openSearch}
          >
            <p className="text-gray-400"> search for product...</p>
          </div>

          <span className="text-white bg-primary p-1 rounded-md">
            <SearchIcon />
          </span>
        </div>

        <div className="flex col-span-3 md:col-span-1 justify-end items-center">
          <div className="hidden lg:flex items-center mr-5">
            <Link to="/wishlist" className="mr-8">
              <Badge badgeContent={wishList.length}>
                <FavoriteBorderIcon />
              </Badge>
            </Link>
            <Link to="/cart">
              <Badge badgeContent={items.length}>
                <ShoppingCartIcon className="h-6 w-6" />
              </Badge>
            </Link>
          </div>

          <div className="flex relative items-center">
            {auth.currentUser == null ? (
              <>
                <div className="md:hidden">
                  <IconButton onClick={openSearch}>
                    <SearchIcon />
                  </IconButton>
                </div>
                <Link to="/login" className="">
                  <button className="flex items-center text-black font-medium py-2 px-4 rounded-md">
                    <p className="mx-2">Sign In</p>
                    <PersonOutlineIcon />
                  </button>
                </Link>
              </>
            ) : (
              <>
                <div className="md:hidden">
                  <IconButton onClick={openSearch}>
                    <SearchIcon />
                  </IconButton>
                </div>
                <DropdownMenu />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
