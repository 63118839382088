import React, { useState, useEffect } from "react";
import "./Carousel.css";

import IconButton from "@mui/material/IconButton";

function Carousel() {
  const sampleData = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/huda-clothing.appspot.com/o/products%2FArtboard%201.jpg?alt=media&token=4df7dc6b-8e76-4dc0-91bf-6fa35de2da7f",
      title: "Casual & Ready to wear collection",
      color: "brown",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/huda-clothing.appspot.com/o/products%2FArtboard%202.jpg?alt=media&token=0cd5f111-2283-441b-bbff-b331b356a01d",
      title: "Classic outfits in a new generation",
      color: "#D76704",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/huda-clothing.appspot.com/o/products%2FArtboard%203.jpg?alt=media&token=27853475-ab14-4afb-be3f-3a73e331c3f7",
      title: "Comfy material, Easy on the skin",
      color: "white",
    },
  ];
  const [current, setCurrent] = useState(0);
  const length = sampleData.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [current, length]);

  return (
    <div className="slider relative w-[100%] h-[100%]">
      <div className="absolute bottom-[20px] z-10 ml-4 ">
        <p className="text-[#1B03A3] text-center text-2xl">shop now</p>
        <span className="">
          <IconButton onClick={nextSlide}>
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 205.64 205.67"
              style={{ height: "30px", color: "green" }}
            >
              <path
                d="M712.79,372.5c0-20.17.09-40.33,0-60.49-.11-16.64-6.59-30-22-37.28a47.4,47.4,0,0,0-19.21-4.3c-41-.37-82-.3-123-.15-24.36.1-41.13,16.87-41.22,41.23q-.22,61.5,0,123c.09,24.37,16.87,41.13,41.23,41.23q61.49.22,123,0c24.36-.1,41.05-16.87,41.23-41.23C712.91,413.82,712.78,393.16,712.79,372.5ZM610,278.51q30,0,60,0c22,.06,34.52,12.47,34.56,34.3q.09,60.24,0,120.47c0,21.6-12.57,34.14-34.16,34.18q-60.24.09-120.47,0c-21.84,0-34.28-12.58-34.31-34.54q-.06-60,0-120c0-21.91,12.51-34.37,34.42-34.43C570,278.47,590,278.51,610,278.51Z"
                transform="translate(-507.2 -270.16)"
              />
              <path
                d="M564.21,372.76,591.92,400c.83-1,2.17-2.57,4.09-4.82L577.9,378.68l1.07-1.76h47.64v-7.63H577.5l19.74-18.12-5.36-5.75C582.56,354.64,573.46,363.63,564.21,372.76Z"
                transform="translate(-507.2 -270.16)"
              />
              <path
                d="M656.5,376.32v-6.66H643.81v6.66Z"
                transform="translate(-507.2 -270.16)"
              />
            </svg>
          </IconButton>
        </span>
        <span className="">
          <IconButton onClick={nextSlide}>
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 205.64 205.67"
              style={{ height: "30px", color: "green" }}
            >
              <path
                d="M507.24,372.5c0-20.17-.1-40.33,0-60.49.11-16.64,6.59-30,22-37.28a47.48,47.48,0,0,1,19.22-4.3c41-.37,82-.3,123-.15,24.37.1,41.13,16.87,41.23,41.23q.22,61.5,0,123c-.1,24.37-16.87,41.13-41.23,41.23q-61.5.22-123,0c-24.37-.1-41.05-16.87-41.24-41.23C507.12,413.82,507.24,393.16,507.24,372.5Zm102.83-94q-30,0-60,0c-22,.06-34.52,12.47-34.55,34.3q-.09,60.24,0,120.47c0,21.6,12.57,34.14,34.17,34.18q60.24.09,120.47,0c21.84,0,34.28-12.58,34.3-34.54q.07-60,0-120c0-21.91-12.51-34.37-34.42-34.43C650.06,278.47,630.06,278.51,610.07,278.51Z"
                transform="translate(-507.2 -270.16)"
              />
              <path
                d="M655.82,372.76c-9.36,9.2-18.47,18.15-27.71,27.25L624,395.19l18.1-16.51-1.06-1.76H593.42v-7.63h49.11l-19.75-18.12,5.37-5.75C637.47,354.64,646.57,363.63,655.82,372.76Z"
                transform="translate(-507.2 -270.16)"
              />
              <path
                d="M563.53,376.32v-6.66h12.68v6.66Z"
                transform="translate(-507.2 -270.16)"
              />
            </svg>
          </IconButton>
        </span>
      </div>

      {sampleData.map((data, index) => (
        <div
          className={index === current ? "slide active " : "slide"}
          key={index}
        >
          {index === current && (
            <div
              style={{
                backgroundImage: `url(${data.image})`,
                color: `${data.color}`,
              }}
              className="slide-image border-2 bg-cover rounded-lg"
            >
              <h1 className="absolute bottom-[90px] md:bottom-28 left-[14px] text-2xl md:text-3xl w-[45%] uppercase">
                {data.title}
              </h1>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Carousel;
