import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  username: "",
  userphoto: "",
  wishList: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      state.userphoto = action.payload.userphoto;
      state.wishList = action.payload.wishList;
    },
  },
});
export default userSlice.reducer;
export const { addUser } = userSlice.actions;
