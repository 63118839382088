import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const plans = [
    {
        name: 'Mobile Money',
        description: 'Pay with your MTN / Airtel mobile money account. Please use number format as follows: 2567xxxxxxxx',
        image: 'https://ug.jumia.is/cms/logo/MTN-Airtel-Logo.png',
        value: 'mobilemoney'
    },
    {
        name: 'Card Payment',
        description: 'All local bank cards accepted. Easy, Fast, and Secure Option.',
        image: 'https://ug.jumia.is/cms/JumiaPay/mastercard-visa.png',
        value: 'card'
    },
    {
        name: 'Cash on Delivery',
        description: 'Pay with Cash On Delivery',
        image: 'https://ug.jumia.is/cms/Icons/i-service-cash.png',
        value: 'cash'
    },
]

export default function CustomRadioGroup({ setPaymentType }) {
    const [selected, setSelected] = useState(plans[0])
    setPaymentType(selected.value)

    return (
        <div className="w-full py-1">
            <div className="mx-auto w-full">
                <RadioGroup value={selected} onChange={setSelected}>
                    <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                    <div className="space-y-2">
                        {plans.map((plan) => (
                            <RadioGroup.Option
                                key={plan.name}
                                value={plan}
                                className={({ active, checked }) =>
                                    `${active
                                        ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                                        : ''
                                    }
                  ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'
                                    }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                }
                            >
                                {({ active, checked }) => (
                                    <>
                                        <div className="flex w-full items-center justify-between">
                                            <div className="flex items-center">
                                                <img src={plan.image} className="w-16 max-h-10 mr-4" />
                                                <div className="text-sm">
                                                    <RadioGroup.Label
                                                        as="p"
                                                        className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                                            }`}
                                                    >
                                                        {plan.name}
                                                    </RadioGroup.Label>
                                                    <RadioGroup.Description
                                                        as="span"
                                                        className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                                                            }`}
                                                    >
                                                        {/* <span>
                                                            {plan.description}
                                                        </span> */}
                                                    </RadioGroup.Description>
                                                </div>
                                            </div>
                                            {checked && (
                                                <div className="shrink-0 text-white">
                                                    <CheckIcon className="h-6 w-6" />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>
        </div>
    )
}

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
