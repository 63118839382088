import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import MiniHeader from "./components/miniHeader/MiniHeader";
import Home from "./containers/home/Home";
import AccountPage from "./containers/accountPage/AccountPage";
import CategoryPage from "./containers/categoryPage/CategoryPage";
import Footer from "./components/footer/Footer";
import ProductOverview from "./containers/productOverviewPage/ProductOverview";
import CartPage from "./containers/cartPage/CartPage";
import { useSelector, useDispatch } from "react-redux";
import { calculateAmount } from "./features/CartSlice";
import CheckoutPage from "./containers/checkoutPage/CheckoutPage";
import Auth from "./containers/auth/Auth";
import { db, auth } from "./huda-config";
import { addUser } from "./features/UserSlice";
import PaymentConfirmed from "./containers/confirm-payment/PaymentConfirmed";
import WishList from "./containers/wishList/WishList";
import PaymentPage from "./containers/paymentPage/PaymentPage";
import TodosList from "./containers/trials/Todos";
import OrderPlacedPage from "./containers/paymentPage/OrderPlacedPage";
import OrdersPage from "./containers/ordersPage/OrdersPage";
import Search from "./containers/search/Search";

function App() {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    dispatch(calculateAmount());
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .doc(user.uid)
          .onSnapshot((snapshot) => {
            dispatch(
              addUser({
                userId: snapshot.data().userId,
                username: snapshot.data().username,
                userphoto: snapshot.data().userPhotoUrl,
                wishList: snapshot.data().userWishList,
              })
            );
          });
      }
    });

    db.collection("products").onSnapshot((snapshot) => {
      setProducts(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          product: doc.data(),
        }))
      );
    });
  }, [cartItems, dispatch]);

  return (
    <div className="App">
      <BrowserRouter>
        <MiniHeader />
        <Header />
        <div className="min-h-screen">
          <Routes>
            <Route path="/" element={<Home data={products} />} />
            <Route path="/home" element={<Home data={products} />} />
            <Route
              path="/:category"
              element={<CategoryPage products={products} />}
            />
            <Route
              path="/:category/:productId/:title"
              element={<ProductOverview />}
            />
            <Route path="/product/:productId" element={<ProductOverview />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/order-placed" element={<OrderPlacedPage />} />
            <Route path="/payment-confirmed" element={<PaymentConfirmed />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/trials" element={<TodosList />} />
            <Route path="/about-us" element={<p>about us</p>} />
            <Route path="/promotions" element={<p>promotions</p>} />
            <Route path="/track-order" element={<p>track order</p>} />
            <Route
              path="/terms-and-conditions"
              element={<p>terms and conditions</p>}
            />
            <Route
              path="/search"
              element={
                <>
                  <Search data={products} />
                  <Home data={products} />
                </>
              }
            />
            <Route path="/contact-us" element={<p>contact us</p>} />
            <Route path="*" element={<p>not found</p>} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
