import React from "react";
import ProductCard from "../../components/productCard/ProductCard";

function ProductSectionPopular({ title, data }) {
  return (
    <div className="mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
        {title}
      </h2>
      <div className="mt-2 border-b-2 border-gray-400"></div>

      <div className="mt-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-10">
        {data.slice(0, 8).map((product, index) => (
          <ProductCard product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
}

export default ProductSectionPopular;
