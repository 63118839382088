import React from "react";
import { Link } from "react-router-dom";
import "./SampleProducts.css";

function SampleProducts({ items }) {
  let { product } = items;

  return (
    <Link to={`${product.category}/${items.id}/${product.title} `}>
      <div className="flex p-1 my-1 overflow-hidden rounded-md cursor-pointer hover:bg-gray-300">
        <img src={product.images} alt=""
          className="aspect-square object-center object-cover w-12 h-12 mr-2 rounded-md"
        />
        <div className="sample-details">
          <p className="text-sm mb-1">
            {product.title}
          </p>
          <p className="text-xs text-gray-400 capitalize hidden md:block">
            Available stock
          </p>
        </div>
      </div>
    </Link>
  );
}

export default SampleProducts;
