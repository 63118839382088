import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui components
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function Search({ data }) {
  const [open, setOpen] = useState(true);
  const [searchFields, setSearchFields] = useState("");
  //  navigation
  const navigate = useNavigate();
  const close = () => {
    navigate("/");
  };

  //   handle search of product
  //   console.log(data);

  const query = data.filter((dt) => {
    const { product } = dt;
    return product.title.toLowerCase().includes(searchFields.toLowerCase());
  });

  console.log(query);

  return (
    <Backdrop open={open} className="z-20" onClick="">
      <div className=" h-full w-full backdrop-blur-[2px] flex justify-center items-center">
        <div className="bg-[#17038a] h-2/3 w-11/12 md:w-[65%] lg:w-[45%] rounded-lg text-white border-[1px] border-[#2704ee] shadow-sm">
          <div className="border-b-[1px] border-[#2704ee] flex items-center p-4 relative">
            <SearchIcon />
            <input
              type="text"
              placeholder="search for product.."
              className="bg-transparent ml-2 outline-none w-1/2"
              onChange={(e) => setSearchFields(e.target.value)}
              value={searchFields}
            />
            <div className="absolute right-2">
              <IconButton onClick={close}>
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
          <div className="border-b-[1px] border-[#2704ee] p-2 h-[80%] overflow-y-scroll">
            {searchFields !== "" ? (
              query.map((item) => {
                const { product, id } = item;
                return (
                  <Link to={`/${product.category}/${id}/${product.title}`}>
                    <div
                      key={id}
                      className="flex my-2 rounded-md p-2 border-[1px] border-[#2704ee]"
                    >
                      <div className="mr-2">
                        <img src={product.images[0]} className="h-14" alt="" />
                      </div>
                      <div>
                        <p> {product.title}</p>
                      </div>
                    </div>
                  </Link>
                );
              })
            ) : (
              <div className="flex justify-center items-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default Search;
