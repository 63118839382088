import React from "react";
import "./DetailTabs.css";

// material-ui components
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ReplayIcon from "@mui/icons-material/Replay";
import PaymentsIcon from "@mui/icons-material/Payments";
function DetailTabs() {
  const tabData = [
    {
      icon: (
        <DeliveryDiningIcon style={{ color: "#cc5500", fontSize: "50px" }} />
      ),
      title: "fast delivery",
      desc: "fast delivery for all online orders",
    },
    {
      icon: <SupportAgentIcon style={{ color: "#cc5500", fontSize: "50px" }} />,
      title: "24/7 support",
      desc: "contact us 24 hours",
    },
    {
      icon: <ReplayIcon style={{ color: "#cc5500", fontSize: "50px" }} />,
      title: "10 day return policy",
      desc: "if goods have damage issues",
    },
    {
      icon: <PaymentsIcon style={{ color: "#cc5500", fontSize: "50px" }} />,
      title: "secure payment",
      desc: "we ensure secure transactions",
    },
  ];
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 md:gap-x-4 lg:gap-x-8">
      {tabData.map((data, index) => (
        <div className="bg-white flex items-center mt-4 p-2 shadow-md" key={index} >
          <div className="w-[100%]">
            <div className="tab-icon flex justify-center  w-[100%]">
              {data.icon}
            </div>
            <p className="font-bold text-sm capitalize text-center mb-1">
              {data.title}
            </p>
            <p className="text-xs text-gray-400 capitalize text-center hidden md:block mb-3">
              {data.desc}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DetailTabs;
