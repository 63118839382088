import React from "react";
import "./confirm.css";
import success from "../../assets/success.gif";
import { Link } from "react-router-dom";

function PaymentConfirmed() {
  // load the page and clear the cart

  return (
    <div className="flex justify-center mt-5 ">
      <div className="root w-3/4 sm:w-1/2 lg:w-1/4 bg-white flex justify-center">
        <div className="">
          <img src={success} alt="" className="" />
          <div className="my-5 text-center cursor-pointer">
            <Link to="/">continue shopping</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentConfirmed;
