import React from "react";
import { Link } from "react-router-dom";

function AuxMenu() {
  const navArray = [
    "home",
    "about-us",
    "promotions",
    "track-order",
    "contact-us",
  ];
  return (
    <div className="flex w-full justify-evenly items-end overflow-hidden mb-2">
      {navArray.map((nav, index) => (
        <Link to="#" key={index}>
          <p className="capitalize mx-3 cursor-pointer border-b-4 hover:border-[#cc5500] whitespace-nowrap text-ellipsis">
            {nav}
          </p>
        </Link>
      ))}
    </div>
  );
}

export default AuxMenu;
