import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [],
  itemNumber: 0,
  amount: 0,
};
const CartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    addToCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity += 1;
      } else {
        const tempProduct = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProduct);
      }

      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    // remove from cart
    removeFromCart: (state, action) => {
      const itemId = action.payload;
      state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    // reduce in the cart
    reduceNumber: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const itemId = action.payload.id;
        state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
      }
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    // increase number
    increaseNumber: (state, action) => {
      if (state.itemNumber <= 1) {
        state.itemNumber = 1;
      } else {
        state.itemNumber = action.payload.itemNumber - 1;
      }
    },

    // clear cart
    clearcart: (state, acion) => {
      state.cartItems = [];
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },
    calculateAmount: (state, action) => {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { data, cartQuantity } = cartItem;
          const { price } = data;
          const itemTotal = cartQuantity * price;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      state.itemNumber = quantity;
      state.amount = total;
    },
  },
});
export default CartSlice.reducer;
export const {
  addToCart,
  removeFromCart,
  increaseNumber,
  reduceNumber,
  calculateAmount,
  clearcart,
} = CartSlice.actions;
export const cartItems = (state) => state.cart.cartItems;
