import React from "react";
import "./SecondBanner.css";

// sample image
import sample from "../../assets/smart.png";
import sitting from "../../assets/sitting.png";
function SecondBanner() {
  return (
    <div className="mt-[5rem]">
      <div className="flex">
        <div className="ml-16 featured-ct bg-[#cc5500] h-[250px] mx-4 mb-[-100px] hidden md:flex ">
          <img src={sample} alt="" className="featured-image mt-[-50px]" />
        </div>
        <div>
          <h2 className="ml-16 text-4xl font-bold tracking-tight text-gray-900 hidden md:block">
            Follow us for more
            <br />
            fashion ideas
          </h2>
        </div>
      </div>
      <div className="f-posts-d2">
        <div className=" h-[200px] bg-[aqua] float-right mt-[3rem]">
          <img src={sitting} alt="" className="h-[350px] mt-[-100px]" />
        </div>
      </div>
    </div>
  );
}

export default SecondBanner;
