import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

export default function TodosList() {
    const [path, setPath] = useState('x')

    useFirestoreConnect([path]) // sync todos collection from Firestore into redux
    const todos = useSelector((state) => state.firestore.ordered.todos)
    useEffect(()=>{
        setPath('todos')
    })
    console.log(todos)

    return (
        <div>
        <ul>
            {todos &&
                todos.map((todo) => (
                    <li>
                        id: {todo.id} todo: {todo.text}
                    </li>
                ))}
        </ul>
        <button onClick={() => setPath('todos')}>Click me</button>
        </div>
    )
}