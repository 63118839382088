import React from "react";
import { useNavigate } from "react-router-dom";
import { XCircleIcon } from "@heroicons/react/outline";

// importing  material-ui components
import Button from "@mui/material/Button";
function WishListItem({ product }) {
  const navigate = useNavigate();
  const viewProduct = () => {
    navigate(
      `/${product.category}/${product.productId}/${product.title}`
    );
  };
  return (
    <div className="flex justify-between items-center mt-4 pt-6">
      <div className="flex items-center">
        <div className="w-16 aspect-square rounded-lg overflow-hidden">
          <img
            src={product.images}
            alt={product.title}
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div className="flex flex-col ml-3">
          <span className="md:text-md font-medium capitalize">{product.title}</span>
          <span className="text-xs font-light text-gray-400 mt-2">UGX {(product.price ?? 0).toLocaleString()}</span>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="pr-8 flex ">
          
        </div>
        <XCircleIcon className="w-6 h-6 text-red-400 cursor-pointer" />
      </div>
    </div>
  );
}

export default WishListItem;
