import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD_RztcE-Hv6VmHmAI-wOSpqnq3b-PMTko",
  authDomain: "huda-clothing.firebaseapp.com",
  projectId: "huda-clothing",
  storageBucket: "huda-clothing.appspot.com",
  messagingSenderId: "816494553939",
  appId: "1:816494553939:web:975666fdeb4ecedea90fa6",
  measurementId: "G-P6DRG674QJ",
});

const analytics = firebase.analytics();
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
export { analytics, db, auth, storage, googleProvider, facebookProvider };
