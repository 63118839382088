import React from "react";
import CartProduct from "../../components/cartProduct/CartProduct";
import Total from "../../components/total/Total";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {ArrowSmLeftIcon} from "@heroicons/react/outline"

export default function CartPage() {
    const products = useSelector((state) => state.cart.cartItems);
    const amount = useSelector((state) => state.cart.amount);

    return (
        <div className="h-screen">

            <div className="py-6">
                <div className="max-w-md mx-auto md:max-w-5xl py-4">
                    <h1 className="text-xl font-medium mx-4">Shopping Cart</h1>
                </div>

                <div className="max-w-md mx-auto bg-gray-100 shadow-lg rounded-lg  md:max-w-5xl">
                    <div className="md:flex ">
                        <div className="w-full px-2 md:px-5 py-5">

                            <div className="md:grid md:grid-cols-3 gap-2 ">

                                <div className="col-span-2 px-1">
                                    {products.length !== 0 ? (
                                        products.map((product, index) => (
                                            <CartProduct key={index} product={product} />
                                        ))
                                    ) : (
                                        <p>cart is empty</p>
                                    )}


                                    <div className="flex justify-between items-center mt-6 pt-6 border-t">
                                        <div className="flex items-center">
                                            <ArrowSmLeftIcon className="h-6 w-6 mr-2 text-primary" />
                                            <span className="text-md  font-medium text-primary">Continue Shopping</span>
                                        </div>

                                        <div className="flex justify-center items-end">
                                            <span className="text-sm font-medium text-gray-400 mr-2">Subtotal:</span>
                                            <span className="text-md font-bold text-gray-800 ">UGX {amount.toLocaleString()}</span>
                                        </div>

                                    </div>
                                </div>


                                <div className="mt-6 md:mt-0 p-5 bg-gray-800 rounded overflow-visible">
                                    <div id="summary" className="px-4 py-5 text-gray-200">
                                        <h1 className="font-semibold text-2xl border-b pb-4">Cart Summary</h1>
                                        <div className="flex justify-between mt-8">
                                            <span className="font-semibold text-sm">Amount</span>
                                            <span className="font-semibold text-sm">UGX {amount.toLocaleString()}</span>
                                        </div>
                                        <div className="flex justify-between mt-4">
                                            <span className="font-semibold text-sm">Discount</span>
                                            <span className="font-semibold text-sm">0%</span>
                                        </div>
                                        <div className="border-t mt-8">
                                            <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                                                <span>Total cost</span>
                                                <span>UGX {amount.toLocaleString()}</span>
                                            </div>
                                            <Link to='/checkout'>
                                                <button className="bg-primary font-semibold py-3 text-sm text-white uppercase w-full rounded-md">
                                                    Checkout
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}