import React, { useState } from "react";
// importing material-ui components
import MenuIcon from "@mui/icons-material/Menu";
import SampleProducts from "../../components/sample/SampleProducts";
import DetailTabs from "../../components/detailTabs/DetailTabs";
import SecondBanner from "../../components/secondBanner/SecondBanner";
import ProductSection from "../../components/productSection/ProductSection";
import Carousel from "../../components/carousel/Carousel";
import Footer from "../../components/footer/Footer";
import SubMenu from "../../components/subMenu/SubMenu";
import IconButton from "@mui/material/IconButton";
import Menu from "../../components/subMenu/Menu";
import SEO from "../../components/seo/SEO";
import AuxMenu from "../../components/auxMenu/AuxMenu";
import ProductSectionPopular from "../../components/productSection/productSectionPopular";
import { Link } from "react-router-dom";

function Home({ data }) {
  // handle menu state
  const [showMenu, setShowMenu] = useState(false);
  const [menu, setMenu] = useState(false);

  // shuffle items
  function shuffleItems(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  var arr = data;
  const randomProducts = shuffleItems(arr);

  return (
    <div className="flex justify-center">
      <SEO
        title="HudA Clothing"
        siteTitle="HudA Clothing"
        description="A clothing line for the best clothes"
      />
      <div>
        <div className="flex justify-center mt-2">
          <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  w-[95%]">
            <div
              className="hidden sm:flex items-center justify-between rounded-md text-white w-[95%] md:w-[97%]
               bg-[#cc5500] px-2 py-1 font-bold mb-2 mr-3 relative cursor-pointer"
              onClick={() => setMenu(!menu)}
            >
              <span className="text-xl font-bold">All Categories</span>
              <IconButton size="small">
                <MenuIcon className="text-white" />
              </IconButton>
              {menu && <Menu data={data} />}
            </div>
            <div className="col-span-2 hidden md:flex">
              <AuxMenu />
            </div>
          </div>
        </div>
        <div className="w-[100%]">
          <div className="mx-2 h-[50vh] sm:h-[70vh] md:h-[90vh] md:mx-5 lg:mx-10  grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
            <div className="row-span-2 hidden md:block">
              <div className=" px-2 h-[90vh] border-2 border-gray-300 rounded-md overflow-hidden">
                {randomProducts.map((item, index) => (
                  <SampleProducts key={index} items={item} />
                ))}
              </div>
            </div>

            <div className="row-span-2 col-span-2 aspect-square">
              <div className="h-[50vh] sm:h-[70vh] md:h-[90vh] flex w-full">
                <Carousel />
              </div>
            </div>

            <div
              className="bg-gray-200 rounded-md hidden lg:block relative hover:opacity-75 cursor-pointer"
              style={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/huda-clothing.appspot.com/o/products%2FIMG_20220617_015155.jpg?alt=media&token=539cb035-e53e-4d07-b695-f25d6246e672)",
                backgroundSize: "cover",
              }}
            >
              <Link to="/fashion" className="block w-full h-full">
                <p className="font-bold text-lg ml-[5px] absolute bottom-5 left-5 text-white">
                  Fashion
                </p>
              </Link>
            </div>
            <div
              className="bg-gray-200 rounded-md hidden lg:block relative hover:opacity-75 cursor-pointer"
              style={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/huda-clothing.appspot.com/o/products%2FIMG_20220529_095722.jpg?alt=media&token=b6a2d503-173f-4b53-a23e-985ea8ad1f92)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Link to="/interiors" className="block w-full h-full">
                <p className="font-bold text-lg ml-[5px] absolute bottom-5 left-5 text-white">
                  Interiors
                </p>
              </Link>
            </div>
          </div>

          {/**
           * mobile categories
           */}

          <div className="mx-2 mt-8 relative">
            <div
              className="flex justify-between rounded-md text-white items-center
          border-2 bg-[#cc5500] py-1 px-4 mt-2  w-[98%] sm:hidden cursor-pointer"
              onClick={() => setShowMenu(!showMenu)}
            >
              <p className="">All Categories</p>
              <IconButton size="small">
                <MenuIcon className="text-white" />
              </IconButton>
            </div>
            <div className="w-[98%]">{showMenu && <SubMenu data={data} />}</div>
          </div>
          {/**
           *
           *banner tabs information
           */}
          <div className="mx-4 lg:mx-10">
            <DetailTabs />
          </div>
          {/*
           * featured posts
           */}
          <div>
            <ProductSection title="Featured Products" data={randomProducts} />
          </div>
          {/*
           *second banner
           */}
          <div>
            <SecondBanner />
          </div>
          {/* popular posts */}
          <div>
            <ProductSectionPopular
              title="Popular Products"
              data={randomProducts}
            />
          </div>
          {/* Trending products */}
          <div>
            <ProductSection title="Trending Products" data={randomProducts} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
