import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ProductCard({ product }) {
  const productId = product.id;
  product = product.product;

  //   button to view the products
  const navigate = useNavigate();
  const veiwProduct = () => {
    navigate(`/${product.category}/${productId}/${product.title} `);
  };

  return (
    <div className="group whitespace-nowrap hover:whitespace-normal">
      <div className="w-full h-72 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
        <Link to={`/${product.category}/${productId}/${product.title} `}>
          <img
            src={product.images[0]}
            alt={product.images}
            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
          />
        </Link>
      </div>
      <div className="mt-2 md:mt-4 flex flex-col md:flex-row justify-between">
        <div className="md:w-[70%]">
          <p className="text-sm capitalize text-gray-700 text-ellipsis overflow-hidden">
            <Link to={`/${product.category}/${productId}/${product.title} `}>
              {product.title}
            </Link>
          </p>
        </div>
        <p className="text-sm font-medium text-gray-900 whitespace-nowrap">
          UGX {product.price.toLocaleString()}
        </p>
      </div>
      <button
        className="mt-2 md:mt-4 px-2 md:px-5 py-1 flex justify-between transition ease-in duration-200 rounded-md hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
        onClick={veiwProduct}
      >
        view product
      </button>
    </div>
  );
}

export default ProductCard;
