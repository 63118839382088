import React from "react";
import WishListItem from "../../components/wishListItem/WishListItem";
import { useSelector } from "react-redux";
import { ArrowSmLeftIcon } from "@heroicons/react/outline"

function WishList() {
  const user = useSelector((state) => state.user);

  return (
    <div className="h-screen">

      <div className="py-6">
        <div className="max-w-md mx-auto md:max-w-5xl py-4">
          <h1 className="text-xl font-medium mx-4">Wishlist</h1>
        </div>

        <div className="max-w-md mx-auto bg-gray-100 shadow-lg rounded-lg  md:max-w-5xl">
          <div className="md:flex ">
            <div className="w-full px-2 md:px-5 py-5">

              <div className="md:grid md:grid-cols-3 gap-2 ">

                <div className="col-span-2 px-1">
                  {user.wishList.length !== 0 ? (
                    user.wishList.map((item, index) => (
                      <WishListItem key={index} product={item} />
                    ))
                  ) : (
                    <p>loading</p>
                  )}


                  <div className="flex justify-between items-center mt-6 pt-6 border-t">
                    <div className="flex items-center">
                      <ArrowSmLeftIcon className="h-6 w-6 mr-2 text-primary" />
                      <span className="text-md  font-medium text-primary">Continue Shopping</span>
                    </div>

                    <div className="flex justify-center items-end">
                      {/* <span className="text-sm font-medium text-gray-400 mr-2">Subtotal:</span> */}
                      {/* <span className="text-md font-bold text-gray-800 ">UGX {amount.toLocaleString()}</span> */}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default WishList;
