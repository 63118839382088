import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./features/CartSlice";
import userReducer from "./features/UserSlice";

import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firebaseReducer } from 'react-redux-firebase'

const fbConfig = {
  apiKey: "AIzaSyD_RztcE-Hv6VmHmAI-wOSpqnq3b-PMTko",
  authDomain: "huda-clothing.firebaseapp.com",
  projectId: "huda-clothing",
  storageBucket: "huda-clothing.appspot.com",
  messagingSenderId: "816494553939",
  appId: "1:816494553939:web:975666fdeb4ecedea90fa6",
  measurementId: "G-P6DRG674QJ",
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable


export const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    cart: cartReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}